@use "theme/msp";
@use "theme/variables" as *;

@use "common/lib/animations";
@use "common/lib/functions";
@use  "common/lib/mixins";
@use "flag-icons/sass/flag-icons" with ($flag-icons-path: "/img/flags");

@use "@fortawesome/fontawesome-free/css/fontawesome";
@use "vis-timeline/dist/vis-timeline-graph2d";
@use "./vendor/bootstrap-mini/bootstrap-mini";
@use "./vendor/bootstrap-toggle";
@use "./vendor/viewer";
@use "./vendor/summernote";
@use "leaflet/dist/leaflet";

$fa-font-path: "/fonts";


@font-face {
    font-display: block;
    font-family: "Font Awesome 5 Free";
    font-style: normal;
    font-weight: 900;
    src:
        url("#{$fa-font-path}/fa-solid-900.woff2") format("woff2"),
        url("#{$fa-font-path}/fa-solid-900.woff") format("woff");
}

@font-face {
    font-display: block;
    font-family: "Font Awesome 5 Free";
    font-style: normal;
    font-weight: 400;
    src:
        url("#{$fa-font-path}/fa-regular-400.woff2") format("woff2"),
        url("#{$fa-font-path}/fa-regular-400.woff") format("woff");
}


@font-face {
    font-display: block;
    font-family: "Font Awesome 5 Brands";
    font-style: normal;
    font-weight: 400;
    src:
        url("#{$fa-font-path}/fa-brands-400.woff2") format("woff2"),
        url("#{$fa-font-path}/fa-brands-400.woff") format("woff");
}


@font-face {
    font-display: block;
    font-family: summernote;
    font-style: normal;
    font-weight: 400;
    src: url("#{$fa-font-path}/summernote.woff") format("woff");
}

.fab {
    font-family: "Font Awesome 5 Brands";
    font-weight: 400;
}

.far {
    font-family: "Font Awesome 5 Free";
    font-weight: 400;
}

.fa,
.fas {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
}

