@use "theme/variables" as *;
@use "vendor/bootstrap-mini/variables" as *;

table {
    background-color: $table-bg;

    col[class*="col-"] {
        display: table-column;
        float: none;
        position: static; // Prevent border hiding in Firefox and IE9-11 (see https://github.com/twbs/bootstrap/issues/11623)
    }

    td,
    th {

        &[class*="col-"] {
            display: table-cell;
            float: none;
            position: static; // Prevent border hiding in Firefox and IE9-11 (see https://github.com/twbs/bootstrap/issues/11623)
        }
    }
}
