@use "theme/variables" as *;
@use "vendor/bootstrap-mini/variables" as *;

.badge {
    background-color: $badge-bg;
    border-radius: $badge-border-radius;
    color: $badge-color;
    display: inline-block;
    font-size: $font-size-small;
    font-weight: $badge-font-weight;
    line-height: $badge-line-height;
    min-width: 10px;
    padding: 3px 7px;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;

    // Empty badges collapse automatically (not available in IE8)

    &:empty {
        display: none;
    }
}
